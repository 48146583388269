export const emailPatternValidation = {
  value:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  message: 'The email format is incorrect',
};

export const passwordMinLengthValidation = {
  value: 8,
  message: 'Password is too short (minimum is 8 characters)',
};

export const cutDownValidationMsg = (msg: string) => {
  return msg.replace('Validation failed: ', '');
};
