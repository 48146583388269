import { gql } from '@apollo/client';

export const GET_MY_USER = gql`
  query GetMyUser {
    getMyUser {
      admin
      frontMedicalId
      frontMedicalIdSigned
      backMedicalId
      backMedicalIdSigned
      confidentialMode
      createdAt
      email
      id
      updatedAt
      validatedState
      profile {
        certificationNumber
        city
        country
        createdAt
        dateOfBirth
        gender
        id
        name
        phoneNumber
        professionalName
        speciality
        subSpeciality
        updatedAt
        institution
      }
    }
  }
`;
