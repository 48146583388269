import { ApolloError, FetchResult, useMutation } from '@apollo/client';
import { FILE_TYPES } from 'const';
import {
  ATTACH_FILES,
  ATTACH_IMAGES,
  ATTACH_VIDEOS,
  FileType,
  IAttachFilesPayload,
  IAttachFilesResponse,
  IAttachImagesPayload,
  IAttachImagesResponse,
  IAttachVideosPayload,
  IAttachVideosResponse,
  ICreateDirectUploadPayload,
  IFileAndChecksum,
  IS3Info,
} from 'services';
import { getMD5HashFromFile } from 'utils';

export const uploadFile = ({ url, file, headers }: IS3Info) => {
  return fetch(url, {
    method: 'PUT',
    body: file,
    headers,
  });
};

export const prepareUploadToS3Info =
  (
    createDirectUpload: (options?: {
      variables: ICreateDirectUploadPayload;
    }) => Promise<FetchResult>
  ) =>
  async ({ file, checksum }: IFileAndChecksum) => {
    const { name, size, type } = file;
    const { data } = await createDirectUpload({
      variables: {
        filename: name,
        byteSize: size,
        contentType: type,
        metadata: JSON.parse('{}'), // TODO: get meta data from uploaded file
        expirationSeconds: 60 * 5,
        checksum,
      },
    });

    const { directUpload, signedId } = data?.createDirectUpload || {};
    const { url = '', headers = {} } = directUpload || {};

    const s3Info: IS3Info = {
      signedId: signedId as string,
      url,
      headers,
      file,
    };

    return s3Info;
  };

export const prepareFileAndContentMD5 = (
  _file: File
): Promise<IFileAndChecksum> => {
  return new Promise<IFileAndChecksum>((resolve, reject) => {
    const fileReader = new FileReader();
    const file = _file;

    fileReader.readAsArrayBuffer(file);
    fileReader.onload = async (event: ProgressEvent<FileReader>) => {
      const result: ArrayBuffer = event.target?.result as ArrayBuffer;
      const fileData = Buffer.from(result);
      const checksum = getMD5HashFromFile(fileData);
      const fileAndChecksum: IFileAndChecksum = { file, checksum, result };
      resolve(fileAndChecksum);
    };

    fileReader.onerror = reject;
  });
};

export const getDomInputFile = (
  selector: string
): HTMLInputElement | null | undefined => {
  return document.querySelector(selector)?.parentNode?.querySelector('input');
};

export const getPreviewImageURL = (file: File) =>
  window.URL.createObjectURL(file);

export const getSignedIdsByType = (
  signedIds: string[],
  uploadedFile: File[]
) => {
  const signedIdsByExt = {
    [FileType.images]: new Array(),
    [FileType.videos]: new Array(),
    [FileType.files]: new Array(),
  };

  const fileTypes = Object.keys(signedIdsByExt);
  const signedIdsAndType = uploadedFile.map((file, index) => {
    const fileExt = '.' + file.name.split('.').pop();
    const [type] = fileTypes.filter((fileType) => {
      return FILE_TYPES[fileType as keyof typeof signedIdsByExt].indexOf(
        fileExt.toLowerCase()
      ) !== -1
        ? true
        : false;
    });

    return {
      signedId: signedIds[index],
      type,
    };
  });

  signedIdsAndType.forEach((idAndType) => {
    const type = FileType[idAndType.type as keyof typeof FileType];
    const group = signedIdsByExt[type];
    group?.push(idAndType.signedId);
  });

  return {
    images: signedIdsByExt[FileType.images],
    videos: signedIdsByExt[FileType.videos],
    files: signedIdsByExt[FileType.files],
  };
};

export const useAttachFileByType = () => {
  const [attachImages, { error: attachImagesError }] =
    useMutation<IAttachImagesResponse, IAttachImagesPayload>(ATTACH_IMAGES);

  const [attachVideos, { error: attachVideosError }] =
    useMutation<IAttachVideosResponse, IAttachVideosPayload>(ATTACH_VIDEOS);

  const [attachFiles, { error: attachFilesError }] =
    useMutation<IAttachFilesResponse, IAttachFilesPayload>(ATTACH_FILES);

  const attachFileByType = async (
    images: string[],
    videos: string[],
    files: string[]
  ) => {
    if (images) {
      await attachImages({
        variables: {
          imageSignedIds: images,
        },
      });
    }

    if (videos) {
      await attachVideos({
        variables: {
          videoSignedIds: videos,
        },
      });
    }

    if (files) {
      await attachFiles({
        variables: {
          fileSignedIds: files,
        },
      });
    }
  };

  return {
    error: attachImagesError || attachVideosError || attachFilesError,
    attachFileByType,
  };
};
