import { gql } from '@apollo/client';
import { COLLECTION_METADATA } from '../common.query';

export const NOTIFICATION = `
  id
  title
  description
  readAt
  notifiableType
  notifiableId
  createdAt
  updatedAt
`;

export const GET_NOTIFICATIONS = gql`
  query GetNotifications($notificationFilter: NotificationFilter!, $page: Int!, $limit: Int!) {
    getNotifications(
      notificationFilter: $notificationFilter, page: $page, limit: $limit) {
      collection {
        ${NOTIFICATION}
      }
      metadata {
        ${COLLECTION_METADATA}
      }
    }
  }
`;

export const GET_NOTIFICATION_PROCEDURE = gql`
  query GetProcedure($procedureId: ID!) {
    getProcedure(procedureId: $procedureId) {
      patient {
        id
      }
    }
  }
`;
