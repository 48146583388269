import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query GetUsers($page: Int, $limit: Int, $validationFilter: ValidationEnum) {
    getUsers(page: $page, limit: $limit, validationFilter: $validationFilter) {
      collection {
        backMedicalId
        backMedicalIdSigned
        frontMedicalId
        frontMedicalIdSigned
        validatedState
        id
        profile {
          name
          gender
          certificationNumber
        }
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

export const GET_USER = gql`
  query GetUser($id: Int!) {
    getUser(id: $id) {
      backMedicalId
      backMedicalIdSigned
      frontMedicalId
      frontMedicalIdSigned
      validatedState
      id
      profile {
        name
        gender
        certificationNumber
      }
    }
  }
`;
