import { gql } from '@apollo/client';

export const CHECK_EMAIL_EXISTS = gql`
  query CheckEmailExists($email: String!) {
    checkEmailExists(email: $email) {
      message
      success
    }
  }
`;

export const GET_SPECIALITIES = gql`
  query GetSpecialities {
    getSpecialities {
      name
      value
      subspecialities {
        name
        value
      }
    }
  }
`;

export const GET_COUNTRIES = gql`
  query GetCountries {
    getCountries
  }
`;
