export const PAGINATION_DEFAULT = {
  currentPage: 1,
  totalPages: 1,
  limitValue: 5,
  totalCount: 1,
};

export const PAGINATION_LIMIT = 5;

export const CARD_PAGINATION_LIMIT: { [key: string]: number } = {
  xs: 5,
  sm: 5,
  md: 8,
  lg: 8,
  xl: 9,
  '2xl': 8,
};

export const MEDICAL_ID_PAGINATION_LIMIT: { [key: string]: number } = {
  xs: 5,
  sm: 5,
  md: 5,
  lg: 10,
  xl: 10,
  '2xl': 10,
};

export const MEDIA_PAGINATION_LIMIT: { [key: string]: number } = {
  xs: 21,
  sm: 21,
  md: 21,
  lg: 21,
  xl: 20,
  '2xl': 20,
};
