import { gql } from '@apollo/client';

export const GET_MY_DASHBOARD_INFO = gql`
  query GetMyDashboardInfo {
    getMyDashboardInfo {
      numberOfCases
      numberOfPatients
      numberOfProcedures
      typeOfSurgery {
        label
        value
      }
      teamRole {
        label
        value
      }
      approach {
        label
        value
      }
      asaClassification {
        label
        value
      }
      malignancy {
        label
        value
      }
      clavienDindo {
        label
        value
      }
      typeOfAnesthesia {
        label
        value
      }
      side {
        label
        value
      }
      procedureCategory {
        label
        value
      }
    }
  }
`;
