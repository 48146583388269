import { onError } from '@apollo/client/link/error';
import { ApolloClient, InMemoryCache, from, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getLocalStorage, clearLocalStorage } from 'utils';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  const token = getLocalStorage('token', '');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const tokenExpired = onError(({ networkError }: any) => {
  if (networkError) {
    if (networkError.statusCode === 401) {
      clearLocalStorage();
      window.location.replace('/');
    }
  }
});

export const client = new ApolloClient({
  link: from([tokenExpired, authLink, httpLink]),
  cache: new InMemoryCache(),
});
