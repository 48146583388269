import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const GA_ID = process.env.REACT_APP_GA_ID;

export const useGA4Tracker = () => {
  const location = useLocation();
  const gaRef = useRef<any>();

  useEffect(() => {
    function sendPageView() {
      gaRef.current.send({
        hitType: 'pageview',
        page: `${location.pathname}${location.search}`,
      });
    }

    if (!gaRef.current) {
      import('react-ga4')
        .then((mod) => (gaRef.current = mod.default))
        .then(() => {
          // only in production
          if (GA_ID && gaRef.current && process.env.NODE_ENV === 'production') {
            gaRef.current.initialize(GA_ID);

            sendPageView();
          }
        });
    } else {
      sendPageView();
    }
  }, [location]);

  return;
};
