import { gql } from '@apollo/client';

export const SEND_LOGIN_OTP = gql`
  mutation SendLoginOTP($email: String!, $password: String!) {
    sendLoginOtp(email: $email, password: $password) {
      message
      success
    }
  }
`;

export const DOCTOR_LOGIN = gql`
  mutation DoctorLogin($email: String!, $password: String!, $otp: String!) {
    doctorLogin(email: $email, password: $password, otp: $otp) {
      message
      success
      token
    }
  }
`;
