import { gql } from '@apollo/client';

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($profileInput: ProfileUpdate!) {
    updateProfile(profileInput: $profileInput) {
      message
      success
    }
  }
`;
