import { gql } from '@apollo/client';
import { COLLECTION_METADATA } from '../common.query';
import { PROCEDURE_DETAIL } from '../procedure';

export const PATIENT = `
  createdAt
  dateOfBirth
  gender
  id
  name
  patientId
  updatedAt
  age
  email
  favorite
  phone
  cases {
    createdAt
    id
    name
    updatedAt
    favorite
    procedureEndDate
    procedureRangeDays
    procedureStartDate
    procedures {
      ${PROCEDURE_DETAIL}
    }
  }
`;

export const GET_PATIENTS_PAYLOAD = `$page: Int!, $limit: Int!, $searchTerm: String, $searchField: PatientSearch, $sortBy: GenericSort`;

export const GET_PATIENTS = gql`
  query GetPatients(${GET_PATIENTS_PAYLOAD}) {
    getPatients(page: $page, limit: $limit, searchTerm: $searchTerm, searchField: $searchField, sortBy: $sortBy) {
      collection {
        ${PATIENT}
      }
      metadata {
        ${COLLECTION_METADATA}
      }
    }
  }
`;

export const GET_FAV_PATIENTS = gql`
  query GetFavPatients($page: Int, $limit: Int, $sortBy: GenericSort) {
    getFavPatients(page: $page, limit: $limit, sortBy: $sortBy) {
      collection {
        ${PATIENT}
      }
      metadata {
        ${COLLECTION_METADATA}
      }
    }
  }
`;

export const GET_FAV_CASES = gql`
  query GetFavCases($page: Int, $limit: Int, $sortBy: GenericSort) {
    getFavCases(page: $page, limit: $limit, sortBy: $sortBy) {
      collection {
        createdAt
        favorite
        id
        name
        procedureEndDate
        procedureRangeDays
        procedureStartDate
        updatedAt
        patient {
          createdAt
          dateOfBirth
          gender
          id
          name
          patientId
          updatedAt
          age
          email
          favorite
          phone
        }
      }
      metadata {
        ${COLLECTION_METADATA}
      }
    }
  }
`;

export const GET_PATIENT = gql`
  query GetPatient($id: Int!) {
    getPatient(id: $id) {
      ${PATIENT}
    }
  }
`;

export const GET_PROCEDURE = gql`
  query GetProcedure($procedureId: ID!) {
    getProcedure(procedureId: $procedureId) {
      ${PROCEDURE_DETAIL}
    }
  }
`;

export const VALIDATE_IMPORT = gql`
  query ValidateImport($signedId: String!) {
    validateImport(signedId: $signedId) {
      col
      remark
      row
    }
  }
`;

export const GET_PROCEDURE_SELECT_OPTIONS = gql`
  query GetProcedureSelectOptions($selectType: SelectTypeEnum!) {
    getProcedureSelectOptions(selectType: $selectType)
  }
`;
