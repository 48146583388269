import { ICollectionMetadata } from '../common.models';

export interface INotification {
  id: string;
  title: string;
  description: string;
  readAt?: string;
  notifiableType: string;
  notifiableId: string;
  createdAt: string;
  updatedAt: string;
}

export interface INotificationPatient {
  id: string;
}

export interface INotificationProcedure {
  patient: INotificationPatient;
}

export interface IGetNotificationsResponse {
  getNotifications: {
    collection: INotification[];
    metadata: ICollectionMetadata;
  };
}

export interface IGetNotificationProcedureResponse {
  getProcedure: INotificationProcedure;
}

export interface IGetNotificationsPayload {
  notificationFilter: string;
  page?: number;
  limit?: number;
}

export interface IGetNotificationProcedurePayload {
  procedureId: string;
}

export interface IMarkNotificationsAsReadPayload {
  notificationIds: string[];
}

export interface IMarkNotificationsAsUnreadPayload {
  notificationIds: string[];
}

export interface IDeleteNotificationsPayload {
  notificationIds: string[];
}

export interface NotificationCardProps {
  notification: INotification;
  onMarkAsRead?: (notificationIds: any) => void;
  onMarkAsUnread?: (notificationIds: any) => void;
  infiniteScrollRef?: any;
}

export enum GetNotificationFilterEnum {
  all = 'all',
  read = 'read',
  unread = 'unread',
}
