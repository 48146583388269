import { gql } from '@apollo/client';
import { COLLECTION_METADATA } from '../common.query';

export const GET_MY_LIBRARY = gql`
  query GetMyLibrary($fileType: FileTypeEnum!, $page: Int!, $limit: Int!) {
    getMyLibrary(fileType: $fileType, page: $page, limit: $limit) {
      collection {
        contentType
        filename
        id
        signedId
        url
      }
      metadata {
        ${COLLECTION_METADATA}
      }
    }
  }
`;

export const GET_LIB_ATTACHMENT = gql`
  query GetLibAttachment($fileType: LibraryTypeEnum!, $signedId: String!) {
    getLibAttachment(fileType: $fileType, signedId: $signedId) {
      contentType
      filename
      id
      signedId
      url
    }
  }
`;
