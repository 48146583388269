import { gql } from '@apollo/client';

export const CREATE_DIRECT_UPLOAD = gql`
  mutation CreateDirectUpload(
    $filename: String!
    $byteSize: Int!
    $checksum: String!
    $contentType: String!
    $metadata: JSON
    $expirationSeconds: Int
  ) {
    createDirectUpload(
      filename: $filename
      byteSize: $byteSize
      checksum: $checksum
      contentType: $contentType
      metadata: $metadata
      expirationSeconds: $expirationSeconds
    ) {
      directUpload {
        headers
        url
      }
      signedId
    }
  }
`;

export const ATTACH_MEDICAL_IDS = gql`
  mutation AttachMedicalIds($frontMedicalId: String!, $backMedicalId: String!) {
    attachMedicalIds(
      frontMedicalId: $frontMedicalId
      backMedicalId: $backMedicalId
    ) {
      message
      success
    }
  }
`;

export const VALIDATE_USER = gql`
  mutation ValidateUser($id: ID!) {
    validateUser(id: $id) {
      message
      success
    }
  }
`;

export const REJECT_USER = gql`
  mutation RejectUser($id: ID!) {
    rejectUser(id: $id) {
      message
      success
    }
  }
`;
