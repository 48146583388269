import { gql } from '@apollo/client';
import { COLLECTION_METADATA } from '../common.query';

export const PROCEDURE_DETAIL = `
ageAtProcedure
anesthesiologistName
asaClassification
approach
anatomicGroup {
  id
  name
}
clavienDindo
comorbidities
complication
complicationNotes
createdAt
dateOfDischarge
dateOfProcedure
diagnosis
firstAssistant
histology
id
institution
malignancy
operationNotes
procedureNotes
organ
patientInstitutionId
procedureId
procedureName
favorite
procedureFiles {
  contentType
  id
  signedId
  url
}
secondAssistant
side
summary
surgeon
teamRole
typeOfAnesthesia
typeOfProcedure
updatedAt
case {
  createdAt
  id
  name
  updatedAt
  favorite
  procedureEndDate
  procedureRangeDays
  procedureStartDate
  procedures {
    dateOfDischarge
    dateOfProcedure
  }
}
patient {
  age
}
consentCode
consentStatus
consentAcceptAt
consentSentAt
consentEmergencyAt
`;

export const GET_FAV_PROCEDURES = gql`
  query GetFavProcedures($page: Int, $limit: Int, $sortBy: GenericSort) {
    getFavProcedures(page: $page, limit: $limit, sortBy: $sortBy) {
      collection {
        id
        diagnosis
        procedureName
        dateOfProcedure
        patient {
          createdAt
          dateOfBirth
          gender
          id
          name
          patientId
          updatedAt
          age
          email
          favorite
          phone
        }
      }
      metadata {
        ${COLLECTION_METADATA}
      }
    }
  }
`;

export const GET_ANATOMIC_GROUP = gql`
  query GetAnatomicGroup {
    getAnatomicGroup {
      id
      name
    }
  }
`;
