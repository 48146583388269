import { IStatus, ICollectionMetadata } from 'services/common.models';

import { IPatient, IProcedure, TeamRoleEnum } from 'services';

export enum TypeOfProcedureEnum {
  elective = 'elective',
  ambulatory = 'ambulatory',
  emergency = 'emergency',
  trauma = 'trauma',
}

export enum MalignancyTypeEnum {
  benign = 'benign',
  malignant = 'malignant',
}

export enum ClavienDindoEnum {
  not_available = 'n/a',
  no = 'no',
  grade_i = 'grade_i',
  grade_ii = 'grade_ii',
  grade_iiia = 'grade_iiia',
  grade_iiib = 'grade_iiib',
  grade_iva = 'grade_iva',
  grade_ivb = 'grade_ivb',
  grade_v = 'grade_v',
}

export enum ProcedureCategoryEnum {
  surgical = 'surgical',
  bedside = 'bedside',
  outpatient = 'outpatient',
}

interface IProcedureInput {
  caseId: string;
  institution?: string;
  patientInstitutionId?: string;
  surgeon?: string;
  firstAssistant?: string;
  secondAssistant?: string;
  teamRole?: TeamRoleEnum | null;
  anesthesiologistName?: string;
  typeOfAnesthesia?: string;
  diagnosis?: string;
  procedureName?: string;
  dateOfProcedure?: string;
  typeOfProcedure?: TypeOfProcedureEnum;
  summary?: string;
  operationNotes?: string;
  malignancy?: string | null;
  anatomicGroupId?: string;
  organ?: string;
  histology?: string;
  comorbidities?: string;
  asaClassification?: string | null;
  clavienDindo?: string | null;
  complication?: string;
  complicationNotes?: string;
  procedureFiles?: string[];
  procedureCategory?: ProcedureCategoryEnum;
}

interface IProcedureInputType {
  id: string;
  institution?: string;
  patientInstitutionId?: string;
  surgeon?: string;
  firstAssistant?: string;
  secondAssistant?: string;
  teamRole?: TeamRoleEnum | null;
  anesthesiologistName?: string;
  typeOfAnesthesia?: string | null;
  diagnosis?: string;
  procedureName?: string;
  dateOfProcedure?: string | null;
  dateOfDischarge?: string | null;
  typeOfProcedure?: TypeOfProcedureEnum | null;
  summary?: string;
  operationNotes?: string;
  procedureNotes?: string;
  malignancy?: MalignancyTypeEnum | null;
  anatomicGroupId?: string;
  organ?: string;
  histology?: string;
  comorbidities?: string;
  asaClassification?: string | null;
  clavienDindo?: ClavienDindoEnum | null;
  complication?: string;
  complicationNotes?: string;
  procedureFiles?: string[];
  consentAcceptAt?: string;
  consentCode?: string;
  consentEmergencyAt?: string;
  consentSentAt?: string;
  consentStatus?: string;
  procedureCategory?: string;
}

export interface IProcedureDetailsAll
  extends IProcedureDetails,
    IDiseaseDetails,
    IComplicationDetails {}

export interface IProcedureDetails {
  diagnosis?: string;
  procedureName?: string;
  dateOfProcedure?: string | null;
  dateOfDischarge?: string | null;
  typeOfProcedure?: TypeOfProcedureEnum | null;
  summary?: string;
  operationNotes?: string;
  procedureNotes?: string;
  approach?: string;
  side?: string;
  patient?: IPatient;
  ageAtProcedure?: string;
  consentAcceptAt?: string;
  consentCode?: string;
  consentEmergencyAt?: string;
  consentSentAt?: string;
  consentStatus?: string;
  procedureCategory?: string;
}

export interface IDiseaseDetails {
  malignancy?: MalignancyTypeEnum | null;
  anatomicGroupId?: string;
  anatomicGroup?: IAnatomicGroup;
  organ?: string;
  histology?: string;
  comorbidities?: string;
  asaClassification?: string | null;
}

export interface IComplicationDetails {
  clavienDindo?: ClavienDindoEnum | null;
  complication?: string;
  complicationNotes?: string;
  procedureFiles?: string[];
}

export interface IAnatomicGroup {
  id: string;
  name: string;
}

export interface IGetAnatomicGroupResponse {
  getAnatomicGroup: IAnatomicGroup[];
}

export interface ICreateAnatomicGroupResponse {
  createAnatomicGroup: IAnatomicGroup[];
}

export interface ICreateAnatomicGroupPayload {
  name: string;
}

export interface ISendConsentPayload {
  procedureId?: string;
}

export interface ISendConsentResponse {
  sendingConsent: IStatus;
}

export interface IAcceptConsentCodePayload {
  procedureId: string;
  consentCode: string;
}

export interface IAcceptConsentCodeResponse {
  acceptConsent: IStatus;
}

export interface IEmergencyProcedurePayload {
  procedureId: string;
}

export interface IEmergencyProcedureResponse {
  emergencyConsent: IStatus;
}

export interface IAddProcedureToCasePayload {
  procedureInputType: IProcedureInput;
}
export interface IAddProcedureToCaseResponse {
  addProcedureToCase: IProcedure;
}

export interface IUpdateProcedurePayload {
  procedureInputType: IProcedureInputType;
}

export interface IUpdateProcedureResponse {
  updateProcedure: IStatus;
}

export enum IConsentStatus {
  pending = 'pending',
  accepted = 'accepted',
  emergency = 'emergency',
  none = 'initial',
}
