import { gql } from '@apollo/client';

export const DOCTOR_REGISTER = gql`
  mutation DoctorRegister($registrationInfo: Registration!) {
    doctorRegister(registrationInfo: $registrationInfo) {
      message
      success
    }
  }
`;

export const RESEND_ACTIVATION = gql`
  mutation ResendActivation($email: String!) {
    resendActivation(email: $email) {
      message
      success
    }
  }
`;

export const ACTIVATE_USER = gql`
  mutation ActivateUser($token: String!) {
    activateUser(token: $token) {
      message
      success
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email) {
      message
      success
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($token: String!, $password: String!) {
    updatePassword(token: $token, password: $password) {
      message
      success
    }
  }
`;
