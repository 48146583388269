import { IStatus } from 'services/common.models';

interface IDirectUpload {
  headers: JSON;
  url: string;
}

interface ICreateDirectUpload {
  directUpload: IDirectUpload;
  signedId: String;
}

export interface IS3Info {
  url: string;
  headers: {};
  signedId: string;
  file: File;
}

export interface IFileAndChecksum {
  file: File;
  checksum: string;
  result: ArrayBuffer;
}

export interface ICreateDirectUploadResponse {
  createDirectUpload: ICreateDirectUpload;
}

export interface ICreateDirectUploadPayload {
  filename: string;
  byteSize: number;
  checksum: string;
  contentType: string;
  metadata: JSON;
  expirationSeconds: number;
}

export interface IAttachMedicalIdsPayload {
  frontMedicalId: string;
  backMedicalId: string;
}

export interface IGetUsers {
  backMedicalId: string;
  backMedicalIdSigned: string;
  frontMedicalId: string;
  frontMedicalIdSigned: string;
  validatedState: string;
  id: string;
  profile: {
    name: string;
    gender: string;
    certificationNumber: string;
  };
}

export interface IGetUsersResponse {
  getUsers: {
    collection: IGetUsers[];
    metadata: {
      currentPage: number;
      limitValue: number;
      totalCount: number;
      totalPages: number;
    };
  };
}

export enum ValidationEnum {
  validated = 'validated',
  rejected = 'rejected',
  pending = 'pending',
  all = 'all',
}
export interface IGetUsersPayload {
  page: number;
  limit: number;
  validationFilter: ValidationEnum;
}

export interface IValidateUserResponse {
  validateUser: IStatus;
}

export interface IValidateUserPayload {
  id: string;
}

export interface IRejectUserResponse {
  rejectUser: IStatus;
}

export interface IRejectUserPayload {
  id: string;
}

export interface IGetUserResponse {
  getUser: IGetUsers;
}

export interface IGetUserPayload {
  id: number;
}
