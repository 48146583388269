import {ComponentType, LazyExoticComponent, ReactNode, lazy} from 'react';

export interface IRoute {
  path: string;
  exact: boolean;
  fallback?: NonNullable<ReactNode> | null; // TODO: create fallback screen
  component?: LazyExoticComponent<ComponentType<any>>;
  routes?: IRoute[];
  redirect?: string;
  private?: boolean;
}

export const routes: IRoute[] = [
  {
    path: '/',
    exact: true,
    component: lazy(() => import('../screens/SignIn/SignIn')),
  },
  {
    path: '/sign-up',
    exact: true,
    component: lazy(() => import('../screens/SignUp/SignUp')),
  },
  {
    path: '/upload/medical-id',
    exact: true,
    component: lazy(() => import('../screens/Upload/UploadMedicalId')),
    private: true,
  },
  {
    path: '/verification',
    exact: true,
    component: lazy(() => import('../screens/Verification/Verification')),
  },
  {
    path: '/activation',
    exact: true,
    component: lazy(() => import('../screens/Activation/Activation')),
  },
  {
    path: '/forget-password',
    exact: true,
    component: lazy(() => import('../screens/ForgetPassword/ForgetPassword')),
  },
  {
    path: '/reset-password',
    exact: true,
    component: lazy(() => import('../screens/ResetPassword/ResetPassword')),
  },
  {
    path: '/patient/list/:page',
    exact: true,
    component: lazy(() => import('../screens/Patient/PatientList')),
    private: true,
  },
  {
    path: '/patient/list',
    exact: true,
    component: lazy(() => import('../screens/Patient/PatientList')),
    private: true,
  },
  {
    path: '/patient/add',
    exact: true,
    component: lazy(() => import('../screens/Patient/PatientAdd')),
    private: true,
  },
  {
    path: '/patient/upload',
    exact: true,
    component: lazy(() => import('../screens/Patient/PatientUpload')),
    private: true,
  },
  {
    path: '/patient/:id/:profile(profile)',
    exact: true,
    component: lazy(() => import('../screens/Patient/PatientDetail')),
    private: true,
  },
  {
    path: '/patient/:id/case/:caseId',
    exact: true,
    component: lazy(() => import('../screens/Patient/PatientDetail')),
    private: true,
  },
  {
    path: '/patient/:id/procedure/:idOfProcedure',
    exact: true,
    component: lazy(() => import('../screens/Patient/PatientDetail')),
    private: true,
  },
  {
    path: '/patient/:id',
    exact: true,
    component: lazy(() => import('../screens/Patient/PatientDetail')),
    private: true,
  },
  {
    path: '/patient/:id/consent',
    exact: true,
    component: lazy(() => import('../screens/Patient/PatientConsent')),
    private: true,
  },
  {
    path: '/user/profile/edit',
    exact: true,
    component: lazy(() => import('../screens/Profile/EditProfile')),
    private: true,
  },
  {
    path: '/user/profile',
    exact: true,
    component: lazy(() => import('../screens/Profile/Profile')),
    private: true,
  },
  {
    path: '/library/:type',
    exact: true,
    component: lazy(() => import('../screens/Library/Library')),
    private: true,
  },
  {
    path: '/library/:type/:id',
    exact: true,
    component: lazy(() => import('../screens/Library/LibraryDetail')),
    private: true,
  },
  {
    path: '/dashboard',
    exact: true,
    component: lazy(() => import('../screens/Dashboard/Dashboard')),
    private: true,
  },
  {
    path: '/verify/medical-id/:status/:page',
    exact: true,
    component: lazy(() => import('../screens/Verify/MedicalIdList')),
    private: true,
  },
  {
    path: '/medical-id/:id',
    exact: true,
    component: lazy(() => import('../screens/MedicalId/MedicalIdDetail')),
    private: true,
  },
  {
    path: '/playground',
    exact: true,
    component: lazy(
      () => import('../components/FilesAttachment/FilesAttachment')
    ),
    private: true,
  },
  {
    path: '/live-stream/:id?',
    exact: true,
    component: lazy(() => import('../screens/LiveStream/LiveStream')),
    private: true,
  },
  {
    path: '/search',
    exact: true,
    component: lazy(() => import('../screens/Search/Search')),
    private: true,
  },
];
