import { TabItem } from 'components/common/Tab/Tab';
import { IStatus, ICollectionMetadata } from 'services/common.models';

export enum FileType {
  images = 'images',
  videos = 'videos',
  files = 'files',
}

export enum QuickActionMediaEnum {
  rename = 'Rename',
  delete = 'Delete',
}

export interface MediaUrl {
  url: string;
}

export interface TabItemsForFilesAttachment extends TabItem {
  type: FileType;
  ext: string[];
}

export interface IFilePreview {
  url: string;
  fileIndex: number;
  signedId?: string;
  filename: string;
  original: File | IFilePreview;
}

export interface IUploadedFile {
  contentType: string;
  filename: string;
  id: string;
  signedId: string;
  url: string;
}
export interface IGetMyLibraryResponse {
  getMyLibrary: {
    collection: IUploadedFile[];
    metadata: ICollectionMetadata;
  };
}
export interface IGetLibAttachmentResponse {
  getLibAttachment: IUploadedFile;
}

export interface IGetMyLibraryPayload {
  fileType: FileType;
  page: number;
  limit: number;
}
export interface IGetLibAttachmentPayload {
  fileType: FileType;
  signedId: string;
}

export interface IAttachImagesPayload {
  imageSignedIds: string[];
}
export interface IAttachImagesResponse {
  imageSignedIds: IStatus;
}

export interface IAttachVideosPayload {
  videoSignedIds: string[];
}
export interface IAttachVideosResponse {
  videoSignedIds: IStatus;
}

export interface IAttachFilesPayload {
  fileSignedIds: string[];
}
export interface IAttachFilesResponse {
  attachFiles: IStatus;
}

export interface IDeleteAttachmentsResponse {
  deleteAttachments: IStatus;
}

export interface IDeleteAttachmentsPayload {
  signedId: string;
  fileType: FileType;
}

export interface IEditAttachmentsResponse {
  editAttachments: IStatus;
}
export interface IEditAttachmentsPayload {
  signedId: string;
  fileType: FileType;
  filename: string;
}
