export const MOCKUP_PATIENT_DATA = {
  createdAt: 'createdAt',
  dateOfBirth: '28 August 1975',
  gender: 'female',
  id: 'id',
  name: 'Laura Oliveira Guilherme Cardoso',
  patientId: 'patientId',
  updatedAt: 'updatedAt',
  age: 38,
  email: 'email@email.com',
  favorite: false,
  phone: '0812345678',
  cases: [],
};

export const MOCKUP_NOTIFICATION_DATA = {
  id: '1',
  title: 'title',
  description: 'description',
  readAt: 'readAt',
  notifiableType: 'notifiableType',
  notifiableId: 'notifiableId',
  updatedAt: 'updatedAt',
  createdAt: 'createdAt'
};

export const MOCKUP_NOTIFICATION_DATA_UNREAD = {
  id: '1',
  title: 'title',
  description: 'description',
  readAt: undefined,
  notifiableType: 'notifiableType',
  notifiableId: 'notifiableId',
  updatedAt: 'updatedAt',
  createdAt: 'createdAt'
};