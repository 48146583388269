import { ApolloProvider } from '@apollo/client';
import { client } from 'providers';
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { routes } from 'router/routeConfig';
import Router from 'router/Router';
import './App.css';

const App = () => {
  return (
    <ApolloProvider client={client}>
      <RecoilRoot>
        <BrowserRouter>
          <Router routes={routes} />
        </BrowserRouter>
      </RecoilRoot>
    </ApolloProvider>
  );
};

export default App;
