import { startCase, omit, identity, pickBy } from 'lodash';
import { DateTime } from 'luxon';
import { useLocation } from 'react-router-dom';

import { DATE_COMPACT_FORMAT, DATE_FULL_FORMAT } from 'const';
import { IProcedure } from 'services';

export const range = function (
  start: number = 0,
  stop: number,
  step: number = 1
): number[] {
  if (!stop) {
    stop = start || 0;
  }

  const calStep = Math.ceil((stop - start) / step);
  const length = Math.max(calStep, 0);
  const range: number[] = [];

  for (let idx = 0; idx < length; idx++, start += step) {
    range.push(start);
  }

  return range;
};

export const getDateOfBirthInfo = (
  dateOfBirthISO: string = '1900-01-01T00:00:00Z'
) => {
  const dateOfBirth = DateTime.fromISO(dateOfBirthISO);
  const currDate = DateTime.now();
  const { years = 0 } = currDate.diff(dateOfBirth, 'year').toObject();
  const age = Math.floor(years);
  const dateFull = dateOfBirth.isValid
    ? dateOfBirth.toFormat(DATE_FULL_FORMAT)
    : '-';
  const dateCompact = dateOfBirth.isValid
    ? dateOfBirth.toFormat(DATE_COMPACT_FORMAT)
    : '-';

  return {
    dateFull,
    dateCompact,
    age,
  };
};

export const removeEmptyValue = (
  data: any
): { [key: string]: string | string[] | null | undefined | unknown } => {
  return Object.fromEntries(Object.entries(data).filter(([_, v]) => !!v));
};

export const getProcedureAndDischargeDate = (procedures: IProcedure[]) => {
  if (procedures.length === 0) {
    return {
      procedureDateISO: '',
      dischargedDateISO: '',
      procedureDate: '...',
      dischargedDate: '...',
    };
  }

  const dateOfProcedures = procedures
    .filter(({ dateOfProcedure }) => dateOfProcedure)
    .map(({ dateOfProcedure }) =>
      dateOfProcedure ? DateTime.fromISO(dateOfProcedure) : DateTime.fromISO('')
    );

  const dateOfDischarges = procedures
    .filter(({ dateOfDischarge }) => dateOfDischarge)
    .map(({ dateOfDischarge }) =>
      dateOfDischarge ? DateTime.fromISO(dateOfDischarge) : DateTime.fromISO('')
    );

  const procedureDateISO = DateTime.min(...dateOfProcedures)?.toISO();
  const dischargedDateISO = DateTime.max(...dateOfDischarges)?.toISO();

  const procedureDate = procedureDateISO
    ? getDateOfBirthInfo(procedureDateISO).dateFull
    : '...';

  const dischargedDate = dischargedDateISO
    ? getDateOfBirthInfo(dischargedDateISO).dateFull
    : '...';

  return {
    procedureDateISO,
    dischargedDateISO,
    procedureDate,
    dischargedDate,
  };
};

export const isoDateOfBirthToAgeText = (
  isoDateOfBirth: string = ''
): string => {
  const dateOfBirth = DateTime.fromISO(isoDateOfBirth);
  const now = DateTime.fromJSDate(new Date());
  const duration = now.diff(dateOfBirth, 'month');
  const ageMonth = Math.floor(duration.months);
  const ageYear = Math.floor(duration.months / 12);
  const ageText: string =
    duration.months <= 24 ? `${ageMonth} Months` : `${ageYear} Years old`;

  return ageText;
};

export const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

export const decimalToPercentString = (decimal: number) =>
  (decimal * 100).toFixed(1) + '%';

export const download = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const replaceItemAtIndex = (
  arr: Array<any>,
  index: number,
  newValue: any
) => {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
};

export const removeItemAtIndex = (arr: Array<any>, index: number) => {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

export const convertToISO = (date: Date | undefined) => {
  if (!date) {
    return undefined;
  }

  return DateTime.fromJSDate(date).toISODate();
};

export const convertFromISO = (date: string | null | undefined) => {
  if (!date) {
    return undefined;
  }

  return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
};

export const convertNull = (field: any) => {
  if (field === '' || field === undefined) {
    return null;
  }

  return field;
};

export const capitalize = (string: string | undefined | null) => {
  if (string === undefined || string === null) {
    return string;
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const urlFileName = (url: string | undefined) => {
  if (url === undefined) {
    return null;
  }
  return url.split('/').pop()?.split('?')[0].split('#')[0].replace(/%20/g, ' ');
};

export const toTitleCase = (str: string) => str.replace(/\w+/g, startCase);

export const removeEmptyFromObject = (obj: object) => {
  return pickBy(obj, identity);
};

export const nullIfBlank = (str: string) => {
  if (str === '') return null;
  return str;
};
