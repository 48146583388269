import { gql } from '@apollo/client';

export const ATTACH_IMAGES = gql`
  mutation AttachImages($imageSignedIds: [String!]!) {
    attachImages(imageSignedIds: $imageSignedIds) {
      message
      success
    }
  }
`;

export const ATTACH_VIDEOS = gql`
  mutation AttachVideos($videoSignedIds: [String!]!) {
    attachVideos(videoSignedIds: $videoSignedIds) {
      message
      success
    }
  }
`;

export const ATTACH_FILES = gql`
  mutation AttachFiles($fileSignedIds: [String!]!) {
    attachFiles(fileSignedIds: $fileSignedIds) {
      message
      success
    }
  }
`;

export const DELETE_ATTACHMENTS = gql`
  mutation DeleteAttachments(
    $signedId: String!
    $fileType: AttachmentTypeEnum!
  ) {
    deleteAttachments(signedId: $signedId, fileType: $fileType) {
      message
      success
    }
  }
`;

export const EDIT_ATTACHMENTS = gql`
  mutation EditAttachments(
    $signedId: String!
    $fileType: AttachmentLibTypeEnum!
    $filename: String!
  ) {
    editAttachments(
      signedId: $signedId
      fileType: $fileType
      filename: $filename
    ) {
      message
      success
    }
  }
`;

export const CLEAR_LIBRARY = gql`
  mutation ClearLibrary($password: String!) {
    clearLibrary(password: $password) {
      message
      success
    }
  }
`;
