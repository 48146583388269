import { gql } from '@apollo/client';
import { PROCEDURE_DETAIL } from 'services';

export const EMERGENCY_CONSENT = gql`
  mutation EmergencyConsent($procedureId: ID!) {
    emergencyConsent(procedureId: $procedureId) {
      message
      success
    }
  }
`;

export const SEND_CONSENT = gql`
  mutation SendingConsent($procedureId: ID!) {
    sendingConsent(procedureId: $procedureId) {
      message
      success
    }
  }
`;

export const ACCEPT_CONSENT_CODE = gql`
  mutation AcceptConsent($procedureId: ID!, $consentCode: String!) {
    acceptConsent(procedureId: $procedureId, consentCode: $consentCode) {
      message
      success
    }
  }
`;

export const DELETE_PROCEDURE = gql`
  mutation DeleteProcedure($procedureId: ID!, $password: String!) {
    deleteProcedure(procedureId: $procedureId, password: $password) {
      message
      success
    }
  }
`;

export const UPDATE_PROCEDURE = gql`
  mutation UpdateProcedure($procedureInputType: ProcedureInputTypeForUpdate!) {
    updateProcedure(procedureInputType: $procedureInputType) {
      message
      success
    }
  }
`;

export const CREATE_ANATOMIC_GROUP = gql`
  mutation CreateAnatomicGroup($name: String!) {
    createAnatomicGroup(name: $name) {
      id
      name
    }
  }
`;

export const ADD_PROCEDURE_TO_CASE = gql`
  mutation AddProcedureToCase($procedureInputType: ProcedureInput!) {
    addProcedureToCase(procedureInputType: $procedureInputType) {
      id
    }
  }
`;

export const CREATE_FOLLOW_UP_TO_PROCEDURE = gql`
  mutation CreateFollowUpToProcedure(
    $parentId: ID!
    $procedureInputType: ProcedureInput!
  ) {
    createFollowUpToProcedure(
      parentId: $parentId
      procedureInputType: $procedureInputType
    ) {
      message
      success
    }
  }
`;
