import { ICollectionMetadata } from 'services/common.models';
import { IGetPatientsPayload } from 'services';

export const searchCategories = [
  {
    label: 'Patient Name',
    name: 'patient_name',
    query: 'name',
    query_type: 'patient',
    confidential: true,
  },
  {
    label: 'SurgeonMate ID',
    name: 'patient_id',
    query: 'patient_id',
    query_type: 'patient',
    confidential: true,
  },
  {
    label: 'Diagnosis',
    name: 'diagnosis',
    query: 'diagnosis',
    query_type: 'procedure',
    confidential: false,
  },
  {
    label: 'Procedure',
    name: 'procedure',
    query: 'name',
    query_type: 'procedure',
    confidential: false,
  },
  {
    label: 'Date of Procedure',
    name: 'date_of_procedure',
    query: 'name',
    query_type: 'procedure',
    confidential: false,
  },
  {
    label: 'Surgeon',
    name: 'surgeon',
    query: 'surgeon',
    query_type: 'procedure',
    confidential: true,
  },
  {
    label: 'Operation Notes',
    name: 'operation_notes',
    query: 'operation_notes',
    query_type: 'procedure',
    confidential: true,
  },
  {
    label: 'Summary',
    name: 'summary',
    query: 'summary',
    query_type: 'procedure',
    confidential: true,
  },
];

export interface IPatientsSearchDetails {
  id: number;
  name: string;
  age: number;
  gender: string;
  cases: {
    id: number;
    name: string;
    procedures: {
      id: number;
      procedureName: string;
      dateOfProcedure: string;
    };
  };
}

export interface IProcedureSearchDetails {
  id: number;
  procedureName: string;
  dateOfProcedure: string;
  patient: {
    id: number;
    name: string;
  };
  case: {
    id: number;
    name: string;
  };
}

export interface IGetPatientsSearchPayload extends IGetPatientsPayload {
  searchField?: string | undefined;
}

export interface IGetProceduresSearchPayload {
  page: number;
  limit: number;
  searchTerm: string;
  searchField?: string | undefined;
  startDateOfProcedure?: string | null;
  endDateOfProcedure?: string | null;
}

export interface IGetProceduresSearchResponse {
  getProceduresSearch: {
    collection: IProcedureSearchDetails[];
    metadata: ICollectionMetadata;
  };
}

// NOTE: uses IGetPatientsPayload from Patients.
export interface IGetPatientsSearchResponse {
  getPatients: {
    collection: IPatientsSearchDetails[];
    metadata: ICollectionMetadata;
  };
}
