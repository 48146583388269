import * as React from 'react';

interface useInfiniteScrollingProps {
  loadingNext: boolean;
  hasMoreRecords: Boolean | undefined;
  handleNextPage: () => void;
}

export const useInfiniteScrolling = ({
  loadingNext = false,
  hasMoreRecords = false,
  handleNextPage = () => {}
}: useInfiniteScrollingProps) => {
  const observer = React.useRef<any>();
  const scrollRef = React.useCallback( (node: any) => {
    if (loadingNext) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMoreRecords) {
        handleNextPage();
      }
    })
    if (node) observer.current.observe(node);
  }, [loadingNext, hasMoreRecords])

  return scrollRef
}
 