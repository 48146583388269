import { gql } from '@apollo/client';

export const MARK_NOTIFICATIONS_AS_READ = gql`
  mutation MarkNotificationsAsRead($notificationIds: [ID!]!) {
    markNotificationsAsRead(
      notificationIds: $notificationIds
    ) {
      message
      success
    }
  }
`

export const MARK_NOTIFICATIONS_AS_UNREAD = gql`
  mutation MarkNotificationsAsUnread($notificationIds: [ID!]!) {
    markNotificationsAsUnread(
      notificationIds: $notificationIds
    ) {
      message
      success
    }
  }
`

export const DELETE_NOTIFICATIONS = gql`
  mutation DeleteNotifications($notificationIds: [ID!]!) {
    deleteNotifications(
      notificationIds: $notificationIds
    ) {
      message
      success
    }
  }
`