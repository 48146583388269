import { FileType } from 'services';

export const FILE_TYPES = {
  [FileType.images]: ['.jpg', '.jpeg', '.png'],
  [FileType.videos]: ['.mov', '.mp4'],
  [FileType.files]: [
    '.pdf',
    '.csv',
    '.doc',
    '.docx',
    '.ppt',
    '.pptx',
    '.xls',
    '.xlsx',
  ],
};
